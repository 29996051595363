/** @jsxImportSource @emotion/react */

import { keyframes } from "@emotion/css";
import { Fade, IconButton, IconButtonProps, Link } from "@mui/material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useMutation } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { backendMediaUrlParser } from "../..";
import { chunkRequests } from "../../functions/chunkRequests";
import { apiErrorParser, instanceApi } from "../../hooks/api";
import { useBetterMediaQuery } from "../../hooks/useBetterMediaQuery";
import { useMe } from "../../hooks/useMe";
import type { CertiblokDocument } from "../../interfaces/Document";
import MenuButton from "../MenuButton";
import AssignExpireDate from "../documents/AssignExpireDate";
import CertiblokIcon, { CertiblokIconNames } from "../icons/CertiblokIcon";
import MLDialog from "../poppers";
import { useDocumentMenuActions } from "./hooks/useDocumentMenuActions";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import DocumentTagsModal from "../../../pages/SingleDocument/components/Tags/DocumentTagsModal";
import { useModalNavigator } from "../ModalNavigator/ModalNavigator";

export type MenuActionType =
	| "delete"
	| "select"
	| "expirationDate"
	| "auditRoom"
	| "move"
	| "rename"
	| "replace"
	| "update"
	| "favorite";

export type DocumentMenuProps = {
	document: CertiblokDocument | CertiblokDocument[];
	onSuccess?: (res: { action: MenuActionType; document: CertiblokDocument | CertiblokDocument[] }) => void;
	onActionComplete?: (res: {
		document: CertiblokDocument | CertiblokDocument[];
		action: "removeExpiration" | "favorite";
	}) => void;
	iconSize?: number;
	shouldShowOptionButton?: boolean;
	isInHeader?: boolean;
	IconButtonProps?: IconButtonProps;
	actionsToRemove?: MenuActionItems[];
};

type MenuActionItems =
	| "delete"
	| "download"
	| "downloadMultiple"
	| "share"
	| "addExpiration"
	| "removeExpiration"
	| "downloadQr"
	| "auditRoom"
	| "move"
	| "rename"
	| "replace"
	| "update"
	| "favorite"
	| "manageTags";

// const AssignExpireDateWithNavigator = withModalNavigator(AssignExpireDate);

const fade = keyframes`
	from {opacity: 0;}
	to {opacity: 1;}
`;

const DocumentMenu: React.FC<DocumentMenuProps> = ({ IconButtonProps, isInHeader, actionsToRemove, ...props }) => {
	const { document, onSuccess, onActionComplete, iconSize = 20, shouldShowOptionButton = true } = props;

	const { t } = useTranslation();
	const { me } = useMe();
	const isMd = useBetterMediaQuery("md");
	const { pushModalById } = useModalNavigator();

	const { isFeatureEnabled } = useFeatureFlags();
	const isQrEnabled = isFeatureEnabled("qrCode");

	const isDocumentArray = Array.isArray(document);
	const singleDocument = Array.isArray(document) ? (document.length === 1 ? document[0] : undefined) : document;

	const actions = useDocumentMenuActions({ onSuccess, document, onActionComplete });

	const [assignExpireDateOpen, setAssignExpireDateOpen] = useState(false);

	const canRemoveFavorite = singleDocument
		? singleDocument?.isFavorite ?? false
		: isDocumentArray && document.every((doc) => doc.isFavorite);
	const canMarkAsFavorite = !canRemoveFavorite;

	const downloadMultiple = useMutation({
		mutationFn: async ({ fileIds }: { fileIds: string[] }) => {
			const downloadBlob = await instanceApi(DocumentApi).generateMultipleDownloadLink({ fileIds: fileIds });
			const link = window.document.createElement("a");
			link.href = backendMediaUrlParser(downloadBlob.data) ?? "";
			link.target = "_blank";
			link.rel = "noopener noreferrer";
			link.click();
		},
		onMutate: () => {
			MLDialog.showSnackbar(t("documents.beginningToDownload"), { variant: "info" });
			onSuccess?.({ action: "update", document: document });
		},
		onSuccess: () => {
			MLDialog.showSnackbar(t("documents.successfullyDownloaded"), { variant: "success" });
		},
		onError: () => {
			MLDialog.showSnackbar(t("documents.errorInDownload"), { variant: "error" });
		},
	});

	const editDocumentExpirationDateMutation = useMutation({
		mutationFn: ({ expireDate, hasNoExpireDate }: { expireDate: string; hasNoExpireDate: boolean }) => {
			const documentsToBatch = Array.isArray(document) ? document : [document];
			return chunkRequests(
				10,
				(document) => {
					return instanceApi(DocumentApi).editDocument(document.id, {
						expireDate,
						noExpiration: hasNoExpireDate,
					});
				},
				documentsToBatch
			);
		},
		onSuccess: (res) => {
			if (res.chunkedErrors.length > 0) {
				MLDialog.showSnackbar(t("documents.editExpirationDatesError", { count: res.chunkedErrors.length }), {
					variant: "error",
				});
			}
			if (res.chunkedSuccessfully.length > 0) {
				onSuccess?.({
					document: isDocumentArray ? res.chunkedSuccessfully.map((elem) => elem.data) : res.chunkedSuccessfully[0].data,
					action: "expirationDate",
				});
			}
			setAssignExpireDateOpen(false);
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const removeDocumentExpirationDateMutation = useMutation({
		onMutate: () => {
			onSuccess?.({
				document: isDocumentArray
					? document.map((doc) => ({ ...doc, expireDate: undefined }))
					: {
							...document,
							expireDate: undefined,
					  },
				action: "expirationDate",
			});
		},
		mutationFn: () => {
			const documentsToBatch = Array.isArray(document) ? document : [document];
			return chunkRequests(
				10,
				(document) => {
					return instanceApi(DocumentApi).editDocument(document.id, {
						expireDate: "null",
					});
				},
				documentsToBatch
			);
		},
		onSuccess: (res) => {
			if (res.chunkedErrors.length > 0) {
				MLDialog.showSnackbar(t("documents.editExpirationDatesError", { count: res.chunkedErrors.length }), {
					variant: "error",
				});
			}
			if (res.chunkedSuccessfully.length > 0) {
				onActionComplete?.({
					document: isDocumentArray ? res.chunkedSuccessfully.map((elem) => elem.data) : res.chunkedSuccessfully[0].data,
					action: "removeExpiration",
				});
			}
		},
		onError: (err) => {
			MLDialog.showSnackbar(apiErrorParser(err), { variant: "error" });
		},
	});

	const buttons: ({ type: MenuActionItems } & (
		| { icon: CertiblokIconNames; children: string; onClick: () => void; shouldShow: boolean; button?: never }
		| {
				icon?: never;
				children?: never;
				onClick?: never;
				button: React.ReactNode;
				shouldShow: boolean;
		  }
	))[] = [
		{
			type: "addExpiration",
			icon: "calendar_outline",
			children: t("global.assignExpireDate"),
			onClick: () => {
				setAssignExpireDateOpen(true);
			},
			shouldShow: true,
		},
		{
			type: "share",
			icon: "load_outline_01",
			children: t("documents.shareFiles"),
			onClick: actions.onShareDocument,
			shouldShow: true,
		},
		{
			type: "auditRoom",
			icon: "profile_outline_01",
			children: t("global.assignAuditRoom"),
			onClick: actions.onAssignAuditRoom,
			shouldShow: true,
		},
		{
			type: "favorite",
			icon: "star_outline",
			children: canMarkAsFavorite ? t("documents.addToFavorite") : t("documents.removeFromFavorite"),
			onClick: () => {
				actions.onToggleFavorite.mutate({ isFavorite: canRemoveFavorite });
			},
			shouldShow: canMarkAsFavorite || canRemoveFavorite,
		},
		{
			type: "move",
			icon: "folder_outline",
			children: t("documents.move"),
			onClick: actions.onMoveDocument,
			shouldShow: isMd,
		},
		{
			type: "rename",
			icon: "edit_outline_02",
			children: t("documents.renameFile"),
			onClick: actions.onRenameDocument,
			shouldShow: singleDocument !== undefined,
		},
		{
			type: "update",
			icon: "paper_outline_03",
			children: singleDocument?.companyId === me?.companyId ? t("documents.updateFile") : t("documents.askFileUpdate"),
			onClick: actions.onUpdateOrRequestDocument,
			shouldShow: singleDocument !== undefined,
		},
		{
			type: "replace",
			icon: "swap_outline",
			children: t("documents.replaceFile"),
			onClick: actions.onReplaceDocument,
			shouldShow: singleDocument !== undefined && singleDocument.companyId !== me?.companyId,
		},
		{
			type: "downloadMultiple",
			icon: "load_outline_02",
			children: t("documents.downloadFiles"),
			shouldShow: singleDocument === undefined,
			onClick: () => {
				downloadMultiple.mutate({
					fileIds: isDocumentArray ? document.map((doc) => doc.id ?? "") : [document.lastVersionFile?.[0]?.id ?? ""],
				});
			},
		},
		{
			type: "download",
			button: (
				<DropdownMenu.Item asChild>
					<Link
						href={backendMediaUrlParser(singleDocument?.lastVersionFile?.[0]?.url, {
							download: true,
							filename: singleDocument?.name ?? singleDocument?.lastVersionFile?.[0]?.filename,
						})}
						component="a"
						target="_blank"
						rel="noopener noreferrer"
						className="no-underline w-full focus-visible:outline-none"
					>
						<MenuButton icon="load_outline_02" className="w-full">
							{t("documents.downloadFiles")}
						</MenuButton>
					</Link>
				</DropdownMenu.Item>
			),
			shouldShow: singleDocument !== undefined,
		},
		{
			type: "downloadQr",
			icon: "load_outline_02",
			children: t("qrCode.downloadQR"),
			onClick: actions.onDownoadQrCode,
			shouldShow: isQrEnabled,
		},
		{
			type: "removeExpiration",
			icon: "close",
			children: t("inbox.removeExpireDate"),
			onClick: () => {
				removeDocumentExpirationDateMutation.mutate();
			},
			shouldShow: true,
		},
		{
			type: "manageTags",
			icon: "tickets_outline_02",
			children: t("tags.manageTags"),
			onClick: () => {
				pushModalById(`document-tags-modal-${singleDocument?.id ?? ""}`);
			},
			shouldShow: singleDocument !== undefined,
		},
	];

	const filteredButtons = buttons.filter((button) => button.shouldShow && !actionsToRemove?.includes(button.type));

	const outerButtons = isInHeader ? filteredButtons : filteredButtons.slice(0, 4);
	const innerButtons = isInHeader ? [] : filteredButtons.slice(4);

	return (
		<>
			<DropdownMenu.Root>
				<Fade appear={false} in={shouldShowOptionButton}>
					<DropdownMenu.Trigger asChild>
						<IconButton {...IconButtonProps}>
							<CertiblokIcon size={iconSize} color="black" name={"more"} />
						</IconButton>
					</DropdownMenu.Trigger>
				</Fade>
				<DropdownMenu.Portal>
					<DropdownMenu.Content
						collisionPadding={15}
						className="min-w-[200px] bg-white box-border flex flex-col p-1 rounded-xl z-[1500] [filter:drop-shadow(0px_1px_18px_rgba(0,0,0,0.12))]"
						css={{
							animation: `${fade} 350ms cubic-bezier(0.4, 0, 0.2, 1)`,
						}}
					>
						{outerButtons.map((button, index) => {
							if (button.button) {
								return <span key={index}>{button.button}</span>;
							}
							return (
								<DropdownMenu.Item asChild key={index}>
									<MenuButton children={button.children} onClick={button.onClick} icon={button.icon} />
								</DropdownMenu.Item>
							);
						})}
						{innerButtons.length > 0 && (
							<>
								<DropdownMenu.Separator className="h-[1px] bg-black-a12 my-1" />
								<DropdownMenu.Sub>
									<DropdownMenu.SubTrigger asChild>
										<MenuButton children={t("global.altro")} icon="arrow_mini_outline_left" />
									</DropdownMenu.SubTrigger>
									<DropdownMenu.Portal>
										<DropdownMenu.SubContent
											sideOffset={8}
											collisionPadding={15}
											data-side="left"
											className="min-w-[200px] bg-white box-border flex flex-col p-1 rounded-xl z-[1500] [filter:drop-shadow(0px_1px_18px_rgba(0,0,0,0.12))]"
											css={{
												animation: `${fade} 350ms cubic-bezier(0.4, 0, 0.2, 1)`,
											}}
										>
											{innerButtons.map((button, index) => {
												if (button.button) {
													return <span key={index}>{button.button}</span>;
												}
												return (
													<DropdownMenu.Item asChild key={index}>
														<MenuButton children={button.children} onClick={button.onClick} icon={button.icon} />
													</DropdownMenu.Item>
												);
											})}
										</DropdownMenu.SubContent>
									</DropdownMenu.Portal>
								</DropdownMenu.Sub>
							</>
						)}

						{!actionsToRemove?.includes("delete") && (
							<>
								<DropdownMenu.Separator className="h-[1px] bg-black-a12 my-1" />
								<DropdownMenu.Item asChild>
									<MenuButton
										onClick={actions.onDeleteDocument}
										children={t("global.moveToBin")}
										icon="delete_outline"
										isDelete
									/>
								</DropdownMenu.Item>
							</>
						)}
						<DropdownMenu.Arrow className="fill-white" />
					</DropdownMenu.Content>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
			<AssignExpireDate
				document={document}
				submitLoading={editDocumentExpirationDateMutation.isLoading}
				onSubmitDate={(date, hasNoExpiration) => {
					editDocumentExpirationDateMutation.mutate({
						expireDate: date.toISO()!,
						hasNoExpireDate: hasNoExpiration,
					});
				}}
				open={assignExpireDateOpen}
				toggleOpen={() => {
					setAssignExpireDateOpen(false);
				}}
			/>
			{singleDocument && (
				<DocumentTagsModal
					id={`document-tags-modal-${singleDocument.id}`}
					document={singleDocument}
					onEditTags={() => {
						onActionComplete?.({ document: singleDocument, action: "removeExpiration" });
					}}
				/>
			)}
		</>
	);
};
export default DocumentMenu;
