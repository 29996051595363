import { IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import CertiblokIcon from "../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../utils/components/ModalNavigator/ModalNavigator";
import { chatAtom } from "../atoms/chatAtom";
import { chatMenuAtom } from "../atoms/chatMenuAtom";
import SelectUserModal from "./SelectUserModal";

type ChatAppBarProps = {};

const ChatAppBar: React.FC<ChatAppBarProps> = (props) => {
	const theme = useTheme();
	const isOverSm = useMediaQuery(theme.breakpoints.up("sm"));
	const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

	const { pushModal } = useModalNavigator();

	const selectedChat = useAtomValue(chatAtom);

	const chatMenuOpen = useAtomValue(chatMenuAtom).open;
	const setChatMenuOpen = useSetAtom(chatMenuAtom);

	console.log("chatMenuOpen", chatMenuOpen);

	return (
		<div className="flex flex-row justify-between items-center gap-3 min-h-[64px]">
			{(chatMenuOpen || isOverLg) && (
				<div className="flex flex-row items-center justify-between gap-3 px-2 py-3 bg-secondary-a06 rounded-t-2xl min-h-[64px] box-border w-full lg:w-[360px]">
					{!isOverLg && (
						<IconButton
							className="w-8 h-8 flex-shrink-0 bg-secondary-dark hover:bg-[#358697] active:bg-[#2e7583] disabled:opacity-50"
							onClick={() => {
								setChatMenuOpen({
									open: false,
								});
							}}
						>
							<CertiblokIcon name="menu-default" className="text-white" />
						</IconButton>
					)}
					<Typography variant={isOverSm ? "h6" : "subtitle1"} sx={{ fontWeight: 800 }}>
						Chat
					</Typography>
					<IconButton
						className="w-8 h-8 flex-shrink-0 bg-secondary-dark hover:bg-[#358697] active:bg-[#2e7583] disabled:opacity-50"
						onClick={() => {
							// createChat("658050023a86ed0014ad028a")
							// 	.then((res) => {
							// 		console.log(res);
							// 		setChat({ chatId: res.data.id });
							// 	})
							// 	.catch((err) => {
							// 		console.error(err);
							// 	});
							pushModal(SelectUserModal);
						}}
					>
						<CertiblokIcon name="add" className="text-white" />
					</IconButton>
				</div>
			)}
			{(!chatMenuOpen || isOverLg) && (
				<div className="flex flex-row items-center justify-start gap-3 px-2 py-3 bg-secondary-a06 rounded-t-2xl min-h-[64px] box-border flex-1">
					{!isOverLg && (
						<IconButton
							className="w-8 h-8 flex-shrink-0 bg-secondary-dark hover:bg-[#358697] active:bg-[#2e7583] disabled:opacity-50"
							onClick={() => {
								setChatMenuOpen({
									open: true,
								});
							}}
						>
							<CertiblokIcon name="menu-default" className="text-white" />
						</IconButton>
					)}
					<Typography variant={isOverSm ? "h6" : "subtitle1"} sx={{ fontWeight: 800 }}>
						{selectedChat?.chatName}
					</Typography>
				</div>
			)}
		</div>
	);
};

export default ChatAppBar;
