import { cn } from "@mabi-ui/utils";
import { Box, Button, keyframes, Skeleton, styled, Tooltip, Typography } from "@mui/material";
import { User } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import avatarPlaceholder from "../../../../assets/avatar-placeholder.png";
import { backendMediaUrlParser } from "../../../../utils";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import { useAbility } from "../../../../utils/hooks/useAbilityRules";
import { useMe } from "../../../../utils/hooks/useMe";
import { useGetCompanyAdmins } from "../../../Profile/pages/Team/hooks/useGetCompanyAdmin";
import { useGetCompanyWithMembers } from "../../../Profile/pages/Team/hooks/useGetCompanyWithMembers";

export type IUser = User & { isGuest?: boolean };

const pulse = keyframes`
0% {
  background-color: #E5E7EB;
}
50% {
  background-color: #F3F4F6;
}
100% {
  background-color: #E5E7EB;
}
`;

const SkeletonCircle = styled(Box)`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	animation: ${pulse} 1500ms infinite;
	background-color: #e5e7eb;
	border: 2px solid white;
`;

const MembersManagement = () => {
	const { t } = useTranslation();
	const SHOWN_MEMBERS_LIMIT = 4;

	const { ability } = useAbility();
	const isAdmin = ability.can("update", "Company");

	const { me, loading } = useMe();

	const { data: companyWithMembers, isLoading: isLoadingMembers } = useGetCompanyWithMembers({ enabled: isAdmin });
	const { data: guests, isLoading: isLoadingGuests } = useGetCompanyAdmins({ options: { enabled: isAdmin } });
	const companyMembersAndGuest = [
		...(me ? [me] : []),
		...(companyWithMembers?.members.filter((member) => member.id !== me?.id) ?? []),
		...(guests?.map((g) => ({ ...g, isGuest: true })) ?? []),
	] as IUser[];
	const isLoadingMembersAndGuests = loading || isLoadingMembers || isLoadingGuests;

	return (
		<div className="flex-shrink-0 flex items-center sm:flex-row flex-col-reverse gap-y-4">
			<div className="flex items-center pr-3 relative h-8 min-w-[50px] justify-center">
				{isLoadingMembersAndGuests ? (
					<>
						{Array(5)
							.fill("placeholder")
							.map((_, i) => (
								<div className="w-[22px]" key={`skeleton_${i}`}>
									<SkeletonCircle key={i} />
								</div>
							))}
					</>
				) : (
					<>
						{companyMembersAndGuest.slice(0, SHOWN_MEMBERS_LIMIT).map((member) => (
							<div className="w-[22px] flex items-center" key={member.id}>
								<Tooltip
									title={`${member.name} ${member.surname}${
										Boolean(member?.isGuest ?? false) ? ` (${t("invitation.role.admin")})`.toUpperCase() : " "
									}`}
									placement="bottom"
								>
									<img
										src={backendMediaUrlParser(member.profileUrl) ?? avatarPlaceholder}
										alt={member.id}
										className={cn(
											"rounded-full flex-shrink-0  bg-gradient-to-br from-[#E1EEE8] to-[#6CC69F] outline-[2px] outline outline-white",
											Boolean(member.isGuest) ? "border-[2px] border-solid w-7 h-7 border-primary" : "w-8 h-8"
										)}
									/>
								</Tooltip>
							</div>
						))}
						{companyMembersAndGuest.slice(SHOWN_MEMBERS_LIMIT).length > 0 && (
							<div className="w-[22px]">
								<Tooltip
									title={companyMembersAndGuest.slice(SHOWN_MEMBERS_LIMIT).map((member) => (
										<span key={member.id} className="block">{`${member.name} ${member.surname}${
											Boolean(member?.isGuest) ? ` (${t("invitation.role.admin")})`.toUpperCase() : ""
										}`}</span>
									))}
									placement="bottom"
								>
									<div
										className="rounded-full w-8 h-8 bg-primary flex items-center justify-center outline-[2px] outline-white outline"
										children={
											<Typography variant="caption" className="text-[10px] text-white">{`+${
												companyMembersAndGuest.slice(SHOWN_MEMBERS_LIMIT).length
											}`}</Typography>
										}
									/>
								</Tooltip>
							</div>
						)}
					</>
				)}
				{isLoadingMembersAndGuests ? (
					<Skeleton width={80} height={16} className="absolute top-[calc(100%+10px)] left-1/2 -translate-x-1/2" />
				) : (
					<Typography
						variant="button"
						noWrap
						className="w-fit absolute top-[calc(100%+10px)] left-1/2 -translate-x-1/2 text-[12px]"
					>
						{t("team.nMembers", { count: companyMembersAndGuest.length ?? 0 })}
					</Typography>
				)}
			</div>
			<Button
				variant="outlined"
				color="primary"
				startIcon={<CertiblokIcon name="profile_outline_03" />}
				className="ml-6"
				href="/profile/team"
			>
				{t("team.invite")}
			</Button>
		</div>
	);
};

export default MembersManagement;
