import { Button } from "@mui/material";
import type { FolderOrDocument } from "certiblok-api-manager";
import { useTranslation } from "react-i18next";
import DocumentMenu, { MenuActionType } from "../../../../../utils/components/DocumentComponents/DocumentMenu";
import ShareDocument from "../../../../../utils/components/documents/ShareDocument";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import { withModalNavigator } from "../../../../../utils/components/ModalNavigatorWrapper";
import type { CertiblokDocument } from "../../../../../utils/interfaces/Document";
import { useToggleFavoriteItemMutation } from "../../../../Favorites/controllers/favoriteQuery";
import DeleteSelectedItemsDialog from "../../../../Folders/components/FoldersHeader/components/DeleteSelectedItemsDialog";

const ShareDocumentWithNavigator = withModalNavigator(ShareDocument);

type AllDocumentsSecondaryActionsProps = {
	selectedItems: FolderOrDocument[];
	isInFavorites?: boolean;
	canRemoveFromFavorite?: boolean;
	onSuccess?: (params: { action: MenuActionType; items: FolderOrDocument[] }) => void;
	onRevalidateItems?: () => void;
};
const AllDocumentsSecondaryActions: React.FC<AllDocumentsSecondaryActionsProps> = ({
	selectedItems,
	isInFavorites,
	onSuccess,
	onRevalidateItems,
	canRemoveFromFavorite,
}) => {
	const { t } = useTranslation();

	const { pushModal, popModal } = useModalNavigator();

	const areAllItemsDocuments = selectedItems.filter((item) => item !== undefined).every((item) => !item.isFolder);

	const toggleFavoriteMutation = useToggleFavoriteItemMutation((favorite) => {
		onSuccess?.({ action: "favorite", items: selectedItems.map((item) => ({ ...item, isFavorite: favorite })) });
	}, onRevalidateItems);

	return (
		<div className="flex-shrink-0 flex items-center gap-2 w-full md:w-fit">
			{areAllItemsDocuments && !isInFavorites && (
				<Button
					color="secondary"
					onClick={() => {
						pushModal(
							ShareDocumentWithNavigator,
							{
								document: selectedItems as CertiblokDocument[],
								revalidateDocument: () => {},
							},
							{
								shouldHidePrev: true,
							}
						).then(() => {
							popModal();
						});
					}}
				>
					<CertiblokIcon size={24} color="secondary" name={"load_outline_01"} />
					{t("shareWithContact.shareCompact")}
				</Button>
			)}

			{isInFavorites && canRemoveFromFavorite && (
				<Button
					color="secondary"
					onClick={() => {
						toggleFavoriteMutation.mutate({
							favorite: false,
							items: selectedItems.map((item) => ({ id: item.id, type: item.isFolder ? "folder" : "document" })),
						});
					}}
				>
					<CertiblokIcon size={24} color="secondary" name={"star_outline"} />
					{t("documents.removeFromFavorite")}
				</Button>
			)}

			<Button
				color="secondary"
				onClick={() => {
					pushModal(DeleteSelectedItemsDialog, {
						items: selectedItems,
					}).then(() => {
						onSuccess?.({ action: "delete", items: [] });
						onRevalidateItems?.();
					});
				}}
			>
				<CertiblokIcon size={24} color="secondary" name={"delete_outline"} />
				{t("global.delete")}
			</Button>

			{areAllItemsDocuments && (
				<DocumentMenu
					IconButtonProps={{
						color: "secondary",
						className: "ml-auto",
					}}
					document={selectedItems as CertiblokDocument[]}
					onSuccess={({ action, document }) => {
						onSuccess?.({ action, items: (Array.isArray(document) ? document : [document]) as FolderOrDocument[] });
					}}
					onActionComplete={() => {
						onRevalidateItems?.();
					}}
					iconSize={24}
					isInHeader
					actionsToRemove={["delete", isInFavorites ? "favorite" : "share"]}
				/>
			)}
		</div>
	);
};
export default AllDocumentsSecondaryActions;
