import { useTranslation } from "react-i18next";
import { CertiblokIconNames } from "../../../../utils/components/icons/CertiblokIcon";
import { useFeatureFlags } from "../../../../utils/hooks/useFeatureFlags";
import DashboardBigCard from "./DashboardBigCard";

const BigCardsGrid = () => {
	const { t } = useTranslation();
	const { isFeatureEnabled } = useFeatureFlags();

	return (
		<div className="grid sm:grid-cols-2 grid-cols-1 items-stretch gap-x-3 gap-y-4 sm:mb-8 mb-4">
			<DashboardBigCard
				icon={"edit-room_outline" as CertiblokIconNames}
				href="/edit_rooms"
				isDisabled={!isFeatureEnabled("editRoom")}
			>
				{t("editRoom.editRoom") ?? ""}
			</DashboardBigCard>
			<DashboardBigCard icon="profile_outline_01" href="/audit_rooms">
				{t("documents.auditRoom")}
			</DashboardBigCard>
		</div>
	);
};

export default BigCardsGrid;
