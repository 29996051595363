"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamsApi = exports.TeamsApiFactory = exports.TeamsApiFp = exports.TeamsApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TeamsApi - axios parameter creator
 * @export
 */
const TeamsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create team
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: (editCreateTeamInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/team/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(editCreateTeamInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete team
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteTeam', 'id', id);
            const localVarPath = `/team/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit team
         * @param {string} id
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam: (id, editCreateTeamInput, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editTeam', 'id', id);
            const localVarPath = `/team/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(editCreateTeamInput, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all company teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyTeams: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/team/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get team members
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getTeamMembers', 'id', id);
            const localVarPath = `/team/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.TeamsApiAxiosParamCreator = TeamsApiAxiosParamCreator;
/**
 * TeamsApi - functional programming interface
 * @export
 */
const TeamsApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.TeamsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create team
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(editCreateTeamInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createTeam(editCreateTeamInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete team
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTeam(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit team
         * @param {string} id
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam(id, editCreateTeamInput, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editTeam(id, editCreateTeamInput, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all company teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyTeams(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllCompanyTeams(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get team members
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTeamMembers(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.TeamsApiFp = TeamsApiFp;
/**
 * TeamsApi - factory interface
 * @export
 */
const TeamsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.TeamsApiFp)(configuration);
    return {
        /**
         *
         * @summary Create team
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(editCreateTeamInput, options) {
            return localVarFp.createTeam(editCreateTeamInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete team
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeam(id, options) {
            return localVarFp.deleteTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit team
         * @param {string} id
         * @param {EditCreateTeamInput} [editCreateTeamInput]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTeam(id, editCreateTeamInput, options) {
            return localVarFp.editTeam(id, editCreateTeamInput, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all company teams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanyTeams(options) {
            return localVarFp.getAllCompanyTeams(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get team members
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamMembers(id, options) {
            return localVarFp.getTeamMembers(id, options).then((request) => request(axios, basePath));
        },
    };
};
exports.TeamsApiFactory = TeamsApiFactory;
/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
class TeamsApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create team
     * @param {EditCreateTeamInput} [editCreateTeamInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    createTeam(editCreateTeamInput, options) {
        return (0, exports.TeamsApiFp)(this.configuration).createTeam(editCreateTeamInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete team
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    deleteTeam(id, options) {
        return (0, exports.TeamsApiFp)(this.configuration).deleteTeam(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit team
     * @param {string} id
     * @param {EditCreateTeamInput} [editCreateTeamInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    editTeam(id, editCreateTeamInput, options) {
        return (0, exports.TeamsApiFp)(this.configuration).editTeam(id, editCreateTeamInput, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all company teams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    getAllCompanyTeams(options) {
        return (0, exports.TeamsApiFp)(this.configuration).getAllCompanyTeams(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get team members
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    getTeamMembers(id, options) {
        return (0, exports.TeamsApiFp)(this.configuration).getTeamMembers(id, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TeamsApi = TeamsApi;
