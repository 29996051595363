/** @jsxImportSource @emotion/react */
import { alpha, FormControl, FormHelperText, InputBase, InputLabel, styled, useTheme } from "@mui/material";
import { normal } from "color-blend";
import { forwardRef, Fragment, useEffect, useRef, useState } from "react";
import { returnTextFieldStatusColor, RGBAtoString } from "../functions/colors";
import { pxToRem } from "../functions/theme";
import type { CertiblokTextInputProps } from "../interfaces/CertiblokTextInputProps";
import type { TextFieldStatus } from "../interfaces/TextFieldStatus";
import CertiblokIcon from "./icons/CertiblokIcon";

function renderIconStatus(status: TextFieldStatus) {
	switch (status) {
		case "success":
			return "success";
		case "loading":
			return "spinner";
		case "error":
			return "comunication_outline_03";
		case "warning":
			return "comunication_outline_01";
		case "info":
			return "comunication_outline_02";
		default:
			return "comunication_outline_02";
	}
}

const CertiblokTextInput = styled(
	forwardRef((props: CertiblokTextInputProps, ref) => {
		const {
			label,
			secondaryLabel,
			helperText,
			status,
			variant = "normal",
			fullWidth,
			containerCss = {},
			inputProps,
			InputProps,
			endAdornment,
			onSubmit,
			...otherProps
		} = { ...props };
		const inputRef = useRef<HTMLDivElement>();
		const theme = useTheme();
		const [innerStatus, setInnerStatus] = useState(status);
		useEffect(() => {
			if (status) setInnerStatus(status);
		}, [status]);

		return (
			<FormControl
				ref={InputProps?.ref}
				variant="standard"
				fullWidth={fullWidth}
				css={{ ...containerCss, boxSizing: "border-box" }}
			>
				{label && (
					<InputLabel
						shrink
						sx={{
							position: "relative",
							transform: "unset",
							fontSize: pxToRem(12),
							lineHeight: "16.37px",
							letterSpacing: 0.4,
							paddingBottom: 1,
							"&.Mui-focused": {
								color: alpha("#000000", 0.74),
							},
						}}
					>
						{label}
					</InputLabel>
				)}
				{secondaryLabel && (
					<InputLabel
						shrink
						sx={{
							position: "relative",
							transform: "unset",
							fontSize: pxToRem(10),
							lineHeight: "12px",
							letterSpacing: 0.75,
							paddingBottom: 1,
							color: alpha("#000000", 0.54),
							"&.Mui-focused": {
								color: alpha("#000000", 0.54),
							},
						}}
					>
						{secondaryLabel}
					</InputLabel>
				)}
				<InputBase
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onSubmit?.();
						}
					}}
					inputProps={inputProps}
					endAdornment={
						variant === "normal" ? (
							endAdornment
						) : (
							<Fragment>
								<CertiblokIcon
									size={16}
									name={renderIconStatus(innerStatus)}
									iconCss={{
										transition: theme.transitions.create(["color", "opacity"]),
										opacity: status !== undefined && helperText !== "" ? 1 : 0,
										color: returnTextFieldStatusColor(theme, props.color, status),
										paddingRight: endAdornment ? 8 : 0,
									}}
								/>
								{endAdornment}
							</Fragment>
						)
					}
					{...otherProps}
					css={{ zIndex: 1 }}
					ref={inputRef}
				/>
				{variant === "normal" && (
					<FormHelperText
						sx={{
							position: "absolute",
							top: inputRef.current?.offsetTop ?? 48,
							transform: `translateY(${
								helperText !== undefined && helperText !== "" ? (props.size === "medium" ? "48px" : "36px") : "24px"
							})`,
							width: "100%",
							boxSizing: "border-box",
							borderRadius: 1,
							fontSize: pxToRem(10),
							fontWeight: 700,
							lineHeight: "10px",
							letterSpacing: 0.75,
							display: "flex",
							alignItems: "center",
							padding: "4px 8px",
							margin: 0,
							marginTop: 1,
							opacity: helperText !== undefined && helperText !== "" ? 1 : 0,
							transition: theme.transitions.create(["background-color", "color", "opacity", "transform"]),
							backgroundColor: alpha(returnTextFieldStatusColor(theme, props.color, status), 0.12),
							color: returnTextFieldStatusColor(theme, props.color, status),
						}}
					>
						<CertiblokIcon color="inherit" size={12} name={renderIconStatus(innerStatus)} iconCss={{ paddingRight: 2 }} />
						{helperText}
					</FormHelperText>
				)}
			</FormControl>
		);
	})
)(({ theme, size, endAdornment, startAdornment, color, status, readOnly, multiline }) => ({
	height: multiline ? "auto" : size === "medium" ? 48 : 36,
	borderRadius: 12,
	borderWidth: "1px",
	borderStyle: "solid",
	borderColor: "transparent",
	backgroundColor:
		color === "secondary"
			? "rgba(244, 250, 251, 1)"
			: RGBAtoString(normal({ r: 255, g: 255, b: 255, a: 1 }, { r: 0, g: 0, b: 0, a: 0.06 })),
	padding: size === "medium" ? "14.5px 12px" : "8.5px 12px",
	transition: theme.transitions.create(["border-color", "background-color", "box-shadow", "filter"]),
	"&:hover": {
		"&:not(.Mui-disabled)": {
			filter: readOnly ? "unset" : "brightness(0.90)",
		},
	},
	"&.Mui-focused": {
		"&:not(input:read-only)": {
			boxShadow: readOnly ? "unset" : `${alpha(returnTextFieldStatusColor(theme, color, status), 0.38)} 0 0 0 2px`,
			borderColor: readOnly ? "transparent" : returnTextFieldStatusColor(theme, color, status),
		},
	},
	"& .MuiInputBase-input": {
		padding: 0,
		paddingLeft: startAdornment ? 8 : 0,
		paddingRight: endAdornment ? 8 : 0,
		fontSize: pxToRem(16),
		lineHeight: "19.1px",
		letterSpacing: 0.25,
	},
}));

export default CertiblokTextInput;
