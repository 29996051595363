import NiceModal from "@ebay/nice-modal-react";
import { UnstyledButton } from "@mabi-ui/button";
import { cn } from "@mabi-ui/utils";
import { Button, Divider, Fade, IconButton, Tooltip } from "@mui/material";
import { ListContactSharedDocumentsOutput } from "certiblok-api-manager";
import { DateTime } from "luxon";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BetterCheckbox from "../../../../../utils/components/BetterCheckbox";
import CertiblokIcon from "../../../../../utils/components/icons/CertiblokIcon";
import { useModalNavigator } from "../../../../../utils/components/ModalNavigator/ModalNavigator";
import { useBetterMediaQuery } from "../../../../../utils/hooks/useBetterMediaQuery";
import SquareIconButton from "../../../../SingleDocument/components/SquareIconButton";
import useGetIsDocumentAvailable from "../../../hooks/useGetIsDocumentAvailable";
import SharedNoteModal from "./SharedNoteModal";
import { Td } from "./SharedNotesTable";
import { UnshareDocumentsModal, UnshareDocumentsWithGroupModal } from "./UnshareDocumentsModal";

const SharedNotesRow = ({
	sharedDoc,
	isLast,
	onRowCheck,
	selectedDocumentsIds,
	userId,
	contactId,
	groupId,
}: {
	sharedDoc: ListContactSharedDocumentsOutput;
	isLast: boolean;
	onRowCheck: () => void;
	selectedDocumentsIds: string[];
	userId?: string;
	contactId?: string;
	groupId?: string;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pushModal } = useModalNavigator();

	const isSm = useBetterMediaQuery("sm");
	const isMd = useBetterMediaQuery("md");

	const { data: isAvailable } = useGetIsDocumentAvailable(sharedDoc.documentId);

	return (
		<Fragment>
			<tr>
				<Td>
					{sharedDoc.isActive ? (
						<BetterCheckbox
							color="secondary"
							checked={selectedDocumentsIds.includes(sharedDoc.documentId)}
							onClick={() => {
								onRowCheck();
							}}
						/>
					) : (
						<BetterCheckbox disabled checked={false} className="opacity-40 [&>div]:bg-grey/[0.0.06]" />
					)}
				</Td>
				<Td colSpan={2}>
					<UnstyledButton
						isDisabled={!isAvailable}
						onClick={() => {
							if (isAvailable) {
								navigate(`/all_documents/document/${sharedDoc.documentId}`, { replace: true });
							}
						}}
						className={cn(
							"rounded-full [&>span]:truncate overflow-hidden max-w-full text-ellipsis",
							isAvailable ? "hover:bg-secondary/[0.06] cursor-pointer" : "cursor-default"
						)}
					>
						{sharedDoc?.documentName}
					</UnstyledButton>
				</Td>
				{isMd && (
					<>
						<Td>{`V${sharedDoc?.fileVersion ?? 0}`}</Td>
					</>
				)}
				<Td>
					{sharedDoc?.shareNote ? (
						<SquareIconButton
							iconName="edit_outline_01"
							onClick={() => NiceModal.show(SharedNoteModal, { note: sharedDoc?.shareNote })}
							iconCss={{ position: "relative", top: "1px" }}
						/>
					) : (
						<div className="h-10" />
					)}
				</Td>
				<Td>{sharedDoc?.createdAt && DateTime.fromISO(sharedDoc?.createdAt).toFormat("dd/LL/yyyy")}</Td>
				<Td>
					{sharedDoc.isActive &&
						(isSm ? (
							<Fade in={selectedDocumentsIds.length === 0}>
								<div className="flex items-center justify-end">
									<Button
										className="mt-auto"
										color="error"
										variant="outlined"
										size="small"
										onClick={() => {
											if (userId && contactId) {
												pushModal(UnshareDocumentsModal, { documentIds: [sharedDoc.documentId], userId, contactId });
											}
											if (groupId) {
												pushModal(UnshareDocumentsWithGroupModal, { documentIds: [sharedDoc.documentId], groupId });
											}
										}}
									>
										{t("shareWithContact.cancelSharing")}
									</Button>
								</div>
							</Fade>
						) : (
							<Tooltip title={t("shareWithContact.interruptSharing")}>
								<IconButton
									className="ml-auto"
									color="error"
									onClick={() => {
										if (userId && contactId) {
											pushModal(UnshareDocumentsModal, { documentIds: [sharedDoc.documentId], userId, contactId });
										}
										if (groupId) {
											pushModal(UnshareDocumentsWithGroupModal, { documentIds: [sharedDoc.documentId], groupId });
										}
									}}
								>
									<CertiblokIcon name="close" color="error" />
								</IconButton>
							</Tooltip>
						))}
				</Td>
			</tr>
			{!isLast && (
				<tr>
					<td colSpan={isMd ? 7 : 6}>
						<Divider className="border-secondary/[0.12]" />
					</td>
				</tr>
			)}
		</Fragment>
	);
};

export default SharedNotesRow;
