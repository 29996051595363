/** @jsxImportSource @emotion/react */
import { Avatar, Button, Typography } from "@mui/material";
import { Contact } from "certiblok-api-manager";
import { useSetAtom } from "jotai";
import { useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { backendMediaUrlParser } from "../../../utils";
import ResponsiveDialog from "../../../utils/components/dialogs/ResponsiveDialog";
import Loading from "../../../utils/components/Loading";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../utils/components/ModalNavigator/ModalNavigator";
import { useMe } from "../../../utils/hooks/useMe";
import { chatAtom } from "../atoms/chatAtom";
import { useCreateChat } from "../hooks/useCreateChat";
import { useGetContacts } from "../hooks/useGetContacts";
import { ProfileType } from "./ChatList";

type SelectUserModalProps = {};

export const MAX_COLUMN_ELEMENTS = 8;
export const BOTTOM_MARGIN = 50;
export const LIST_HEIGHT = (32 + 8) * MAX_COLUMN_ELEMENTS + BOTTOM_MARGIN;
export const ITEM_WIDTH = 300 + 48;

const SelectUserModal = ModalNavigator.create((props: SelectUserModalProps) => {
	const { visible } = useModalRoute();
	const { popModal } = useModalNavigator();

	const { me } = useMe();

	const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useGetContacts();

	const contacts = useMemo(() => {
		return data?.pages?.reduce((acc, page) => [...acc, ...page.data], [] as Contact[]) ?? [];
	}, [data]);

	const { mutateAsync: createChat } = useCreateChat();

	const setChat = useSetAtom(chatAtom);

	return (
		<ResponsiveDialog
			open={visible}
			onClose={popModal}
			header={
				<Typography variant="h6" className="font-bold">
					Select user
				</Typography>
			}
			footer={
				<div className="flex flex-row justify-end items-center">
					<Button color="inherit" onClick={popModal}>
						Chiudi
					</Button>
				</div>
			}
		>
			<div className="flex flex-col py-6 w-full max-w-[420px] max-h-[600px] overflow-auto">
				<Virtuoso
					className="w-full min-w-[420px] flex-1 min-h-[40vh] md:min-h-[unset]: h-full"
					data={contacts}
					endReached={() => {
						if (hasNextPage && !isFetching) {
							fetchNextPage();
						}
					}}
					components={{
						Footer: () => {
							if (isFetching) {
								return <Loading title={"Caricamento contatti"} />;
							}

							return null;
						},
					}}
					itemContent={(index, contact) => {
						return (
							<Button
								key={contact.id}
								className="w-full normal-case"
								size="large"
								onClick={() => {
									createChat({ memberId: contact?.linkedUserId!, chatName: "Test chat" })
										.then((res) => {
											console.log(res);

											const profile = res.data?.members?.data?.find(
												(member) => (member as ProfileType).uid !== me?.id
											) as ProfileType;

											setChat({ chatId: res.data.id, chatName: profile.display_name ?? res.data.display_name });
											popModal();
										})
										.catch((err) => {
											console.error(err);
										});
								}}
							>
								<div className="w-full flex flex-row items-center gap-2">
									<Avatar
										sx={{
											width: 24,
											height: 24,
											typography: "caption",
											color: "white",
											backgroundColor: "orange.main",
										}}
										src={backendMediaUrlParser(contact?.profileUrl) ?? undefined}
										children={
											contact?.profileUrl === undefined
												? contact?.name.charAt(0).toUpperCase() + contact?.surname?.charAt(0).toUpperCase()
												: undefined
										}
									/>
									<Typography variant="body2" className="flex-1 text-black text-left">
										{`${contact?.name} ${contact?.surname}`}
									</Typography>
								</div>
							</Button>
						);
					}}
				/>
			</div>
		</ResponsiveDialog>
	);
});

export default SelectUserModal;
