import { Tab, TabProps } from "@headlessui/react";
import driveLogo from "../../../../../assets/cloud_import/drive.png";
import dropboxLogo from "../../../../../assets/cloud_import/dropbox.png";
import oneDriveLogo from "../../../../../assets/cloud_import/onedrive.png";
import { useTranslation } from "react-i18next";
import { UnstyledButton } from "@mabi-ui/button";
import { Typography } from "@mui/material";
import { FC, Fragment, ReactNode } from "react";
import { cn } from "@mabi-ui/utils";

const CloudTab: FC<TabProps<any> & { image: ReactNode; title: string }> = ({ image, title, ...props }) => {
	return (
		<Tab as={Fragment}>
			{({ selected }) => (
				<UnstyledButton
					className={cn(
						"py-4 px-3 bg-transparent rounded-2xl border-black/[0.12] border-solid border-[1px] w-[min(calc(30%-6px),130px)] outline-2 outline outline-transparent",
						selected && "border-primary outline-primary bg-primary/[0.06]"
					)}
					{...props}
				>
					<div className="w-full h-[24px]">{image}</div>
					<Typography variant="body2" className="mt-3">
						{title}
					</Typography>
				</UnstyledButton>
			)}
		</Tab>
	);
};

const CloudTabs = ({
	selectedTab,
	setSelectedTab,
}: {
	selectedTab: number;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const { t } = useTranslation();
	return (
		<Tab.Group selectedIndex={selectedTab} onChange={(i) => setSelectedTab(i)}>
			<Tab.List className={"w-full flex gap-6 justify-center"}>
				<CloudTab
					image={<img src={driveLogo} alt={t("cloudImport.googleDrive")} className="h-full w-full object-contain " />}
					title={t("cloudImport.googleDrive")}
				/>
				<CloudTab
					image={<img src={dropboxLogo} alt={t("cloudImport.dropbox")} className="h-full w-full object-contain" />}
					title={t("cloudImport.dropbox")}
				/>
				<CloudTab
					image={<img src={oneDriveLogo} alt={t("cloudImport.oneDrive")} className="h-full w-full object-contain" />}
					title={t("cloudImport.oneDrive")}
				/>
			</Tab.List>
		</Tab.Group>
	);
};

export default CloudTabs;
