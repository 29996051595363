"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrashApi = exports.TrashApiFactory = exports.TrashApiFp = exports.TrashApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TrashApi - axios parameter creator
 * @export
 */
const TrashApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Empty trash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptyTrash: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all documents in trash folder
         * @param {string} folderId
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentsInTrashFolder: (folderId, page, perPage, search, onlyFolders, sorting, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            (0, common_1.assertParamExists)('getAllDocumentsInTrashFolder', 'folderId', folderId);
            const localVarPath = `/document/trash/{folderId}/`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (onlyFolders !== undefined) {
                localVarQueryParameter['onlyFolders'] = onlyFolders;
            }
            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get all root documents in trash
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRootDocumentsInTrash: (page, perPage, search, onlyFolders, sorting, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }
            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }
            if (onlyFolders !== undefined) {
                localVarQueryParameter['onlyFolders'] = onlyFolders;
            }
            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Recover files from trash
         * @param {InputRecoverFilesFromTrash} [inputRecoverFilesFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFilesFromTrash: (inputRecoverFilesFromTrash, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/recover/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRecoverFilesFromTrash, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Recover folders from trash
         * @param {InputRecoverFoldersFromTrash} [inputRecoverFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFoldersFromTrash: (inputRecoverFoldersFromTrash, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/recover/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRecoverFoldersFromTrash, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove documents from trash
         * @param {InputRemoveDocumentsFromTrash} [inputRemoveDocumentsFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentsFromTrash: (inputRemoveDocumentsFromTrash, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/remove/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRemoveDocumentsFromTrash, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove folders from trash
         * @param {InputRemoveFoldersFromTrash} [inputRemoveFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFoldersFromTrash: (inputRemoveFoldersFromTrash, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document/trash/remove/folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputRemoveFoldersFromTrash, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.TrashApiAxiosParamCreator = TrashApiAxiosParamCreator;
/**
 * TrashApi - functional programming interface
 * @export
 */
const TrashApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.TrashApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Empty trash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptyTrash(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.emptyTrash(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all documents in trash folder
         * @param {string} folderId
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get all root documents in trash
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Recover files from trash
         * @param {InputRecoverFilesFromTrash} [inputRecoverFilesFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFilesFromTrash(inputRecoverFilesFromTrash, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recoverFilesFromTrash(inputRecoverFilesFromTrash, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Recover folders from trash
         * @param {InputRecoverFoldersFromTrash} [inputRecoverFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove documents from trash
         * @param {InputRemoveDocumentsFromTrash} [inputRemoveDocumentsFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Remove folders from trash
         * @param {InputRemoveFoldersFromTrash} [inputRemoveFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFoldersFromTrash(inputRemoveFoldersFromTrash, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeFoldersFromTrash(inputRemoveFoldersFromTrash, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.TrashApiFp = TrashApiFp;
/**
 * TrashApi - factory interface
 * @export
 */
const TrashApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.TrashApiFp)(configuration);
    return {
        /**
         *
         * @summary Empty trash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emptyTrash(options) {
            return localVarFp.emptyTrash(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all documents in trash folder
         * @param {string} folderId
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options) {
            return localVarFp.getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all root documents in trash
         * @param {number} [page]
         * @param {number} [perPage]
         * @param {string} [search]
         * @param {string} [onlyFolders]
         * @param {object} [sorting]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options) {
            return localVarFp.getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Recover files from trash
         * @param {InputRecoverFilesFromTrash} [inputRecoverFilesFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFilesFromTrash(inputRecoverFilesFromTrash, options) {
            return localVarFp.recoverFilesFromTrash(inputRecoverFilesFromTrash, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Recover folders from trash
         * @param {InputRecoverFoldersFromTrash} [inputRecoverFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options) {
            return localVarFp.recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove documents from trash
         * @param {InputRemoveDocumentsFromTrash} [inputRemoveDocumentsFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options) {
            return localVarFp.removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove folders from trash
         * @param {InputRemoveFoldersFromTrash} [inputRemoveFoldersFromTrash]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFoldersFromTrash(inputRemoveFoldersFromTrash, options) {
            return localVarFp.removeFoldersFromTrash(inputRemoveFoldersFromTrash, options).then((request) => request(axios, basePath));
        },
    };
};
exports.TrashApiFactory = TrashApiFactory;
/**
 * TrashApi - object-oriented interface
 * @export
 * @class TrashApi
 * @extends {BaseAPI}
 */
class TrashApi extends base_1.BaseAPI {
    /**
     *
     * @summary Empty trash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    emptyTrash(options) {
        return (0, exports.TrashApiFp)(this.configuration).emptyTrash(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all documents in trash folder
     * @param {string} folderId
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {string} [search]
     * @param {string} [onlyFolders]
     * @param {object} [sorting]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options) {
        return (0, exports.TrashApiFp)(this.configuration).getAllDocumentsInTrashFolder(folderId, page, perPage, search, onlyFolders, sorting, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get all root documents in trash
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {string} [search]
     * @param {string} [onlyFolders]
     * @param {object} [sorting]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options) {
        return (0, exports.TrashApiFp)(this.configuration).getAllRootDocumentsInTrash(page, perPage, search, onlyFolders, sorting, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Recover files from trash
     * @param {InputRecoverFilesFromTrash} [inputRecoverFilesFromTrash]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    recoverFilesFromTrash(inputRecoverFilesFromTrash, options) {
        return (0, exports.TrashApiFp)(this.configuration).recoverFilesFromTrash(inputRecoverFilesFromTrash, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Recover folders from trash
     * @param {InputRecoverFoldersFromTrash} [inputRecoverFoldersFromTrash]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options) {
        return (0, exports.TrashApiFp)(this.configuration).recoverFoldersFromTrash(inputRecoverFoldersFromTrash, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove documents from trash
     * @param {InputRemoveDocumentsFromTrash} [inputRemoveDocumentsFromTrash]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options) {
        return (0, exports.TrashApiFp)(this.configuration).removeDocumentsFromTrash(inputRemoveDocumentsFromTrash, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove folders from trash
     * @param {InputRemoveFoldersFromTrash} [inputRemoveFoldersFromTrash]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrashApi
     */
    removeFoldersFromTrash(inputRemoveFoldersFromTrash, options) {
        return (0, exports.TrashApiFp)(this.configuration).removeFoldersFromTrash(inputRemoveFoldersFromTrash, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TrashApi = TrashApi;
