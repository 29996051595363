import { useMutation, useQueryClient } from "@tanstack/react-query";
import { WeavyApi } from "certiblok-api-manager";
import { instanceApi } from "../../../utils/hooks/api";

export const useCreateChat = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ memberId, chatName }: { memberId: string; chatName: string }) => {
			return await instanceApi(WeavyApi).createWeavyChat({
				chatName: "Test chat",
				memberIds: [memberId],
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["getAllUserChats"]);
		},
	});
};
