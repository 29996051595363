import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ImportFromCloudModal from "../../../../../../../pages/Profile/components/ImportFromCloud/ImportFromCloudModal";
import { useBetterMediaQuery } from "../../../../../../hooks/useBetterMediaQuery";
import CertiblokIcon from "../../../../../icons/CertiblokIcon";
import { ScaffoldContext } from "../../../../controllers/ScaffoldContext";
import CollapsableButton from "../CollapsableButton";

const CollapsableButtons = () => {
	const { t } = useTranslation();
	const { open, toggleSendVideoConferenceModalOpen } = useContext(ScaffoldContext);
	const isMd = useBetterMediaQuery("md");

	const [openCloudModal, setOpenCloudModal] = useState(false);

	return (
		<div className="flex flex-col gap-4 w-full mt-6">
			<CollapsableButton
				open={isMd ? open : true}
				icon={<CertiblokIcon size={24} color="inherit" name={"cloud_outline"} />}
				children={t("cloudImport.importFromOtherClouds")}
				variant="contained"
				{...((open || !isMd) && { fullWidth: true })}
				color="primary"
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
				}}
				size="medium"
				onClick={() => {
					setOpenCloudModal(true);
				}}
			/>

			<CollapsableButton
				open={isMd ? open : true}
				// TODO MEM: manca l'icona sync
				icon={<CertiblokIcon size={24} color="inherit" name={"folder_outline"} />}
				children={t("dashboard.syncFiles")}
				variant="contained"
				color="secondary"
				{...((open || !isMd) && { fullWidth: true })}
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
					backgroundColor: "#80cbdb",
					":hover": {
						backgroundColor: "#91d0dc",
					},
				}}
				// disabled={true}
				size="medium"
				onClick={() => {}}
			/>

			<CollapsableButton
				open={isMd ? open : true}
				icon={<CertiblokIcon size={24} color="inherit" name={"video_outline"} />}
				children={t("variousComponents.videoConference")}
				variant="contained"
				color="secondary"
				{...((open || !isMd) && { fullWidth: true })}
				sx={{
					gap: 2,
					height: 40,
					flexShrink: 0,
				}}
				size="medium"
				onClick={() => {
					toggleSendVideoConferenceModalOpen();
				}}
			/>

			<ImportFromCloudModal open={openCloudModal} setOpen={setOpenCloudModal} />
		</div>
	);
};

export default CollapsableButtons;
