/** @jsxImportSource @emotion/react */

import { cn } from "@mabi-ui/utils";
import { Box, Button, Fade, IconButton, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import clsx from "clsx";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { backendMediaUrlParser } from "../..";
import avatarPlaceholder from "../../../assets/avatar-placeholder.png";
import companyPlaceholder from "../../../assets/company-placeholder.png";
import logotype from "../../../assets/logo/logotype.png";
import { LogoutModalWithNiceModal } from "../../../pages/Profile/components/LogoutModal";
import { LG_DRAWER_CLOSED_WIDTH, LG_DRAWER_OPEN_WIDTH } from "../../constants/DrawerConstants";
import { SX_HEIGHT } from "../../constants/TopBarConstants";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useMe } from "../../hooks/useMe";
import { useModalNavigator } from "../ModalNavigator/ModalNavigator";
import StyledAppBar from "../StyledAppBar";
import CertiblokIcon from "../icons/CertiblokIcon";
import CompanySelector from "./components/CompanySelector";
import GlobalAutocomplete from "./components/GlobalAutocomplete";
import SelectedButton from "./components/SelectedButton";
import NotificationPopper from "./components/notifications/NotificationPopper";
import { ScaffoldContext } from "./controllers/ScaffoldContext";
import LockDrawerButton from "./components/LockDrawerButton";

const AppBar = () => {
	const { t } = useTranslation();
	const {
		open,
		setOpen,
		notificationCount,
		markAllNotificationAsRead,
		markNotificationAsRead,
		setLockDrawerOpen,
		lockDrawerOpen,
	} = useContext(ScaffoldContext);

	const [notificationOpen, setNotificationOpen] = useState(false);
	const [companySelectorOpen, setCompanySelectorOpen] = useState(false);

	const notificationAnchorEl = useRef(null);
	const companySelectorAnchorEl = useRef(null);

	const location = useLocation();

	const { me } = useMe();

	const isInProfileSection = location.pathname.includes("profile");

	const { isFeatureEnabled } = useFeatureFlags();
	const isGuestInvitesEnabled = isFeatureEnabled("guestInvites");

	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.up("md"));
	const isLg = useMediaQuery(theme.breakpoints.up("lg"));
	const isExactlyLg = useMediaQuery(theme.breakpoints.only("lg"));

	const [showLockButton, setShowLockButton] = useState(false);

	const { pushModal } = useModalNavigator();

	const selectedCompany =
		me?.company !== null && me?.selectedCompanyId === me?.companyId
			? me?.company
			: // @ts-ignore
			  me?.managedCompanies?.find((company) => {
					return company?.id === me?.selectedCompanyId;
			  });

	const isCompanySelectorVisible =
		// @ts-ignore
		(me?.managedCompanies?.length > 0 && me?.company !== null) || me?.managedCompanies?.length > 1;

	return (
		<StyledAppBar
			position="fixed"
			open={open}
			sx={{
				backgroundColor: { xs: "background.default", md: "transparent" },
				zIndex: 1200,
				height: {
					xs: SX_HEIGHT,
				},
				width: {
					sx: "100%",
					md: open ? `calc(100% - ${LG_DRAWER_OPEN_WIDTH}px)` : `calc(100% - ${LG_DRAWER_CLOSED_WIDTH}px)`,
				},
			}}
		>
			<Toolbar
				sx={{
					zIndex: 1200,
					gap: {
						xs: 1,
						md: 2,
						lg: 6,
					},
					width: "100%",
					transition: theme.transitions.create(["width"]),
					paddingLeft: {
						xs: 3,
						md: 12,
					},
					paddingRight: {
						xs: 3,
						lg: 8,
					},
					position: "relative",
				}}
			>
				<IconButton
					size="small"
					color="primary"
					onClick={() => {
						if (isExactlyLg && lockDrawerOpen) {
							setLockDrawerOpen(false);
						}
						setShowLockButton(false);
						setOpen(!open);
					}}
					onMouseEnter={() => {
						isExactlyLg && !lockDrawerOpen && open && setShowLockButton(true);
					}}
					sx={{
						backgroundColor: { md: "white" },
						boxShadow: { md: "0px 1px 10px rgba(0, 0, 0, 0.12)" },
						flexGrow: 0,
						flexShrink: 0,
						position: { xs: "relative", md: "absolute" },
						top: { md: 19 },
						left: { md: -18 },
						transition: theme.transitions.create(["box-shadow", "transform", "background-color"]),
						zIndex: 1200,
						"&:hover": {
							backgroundColor: { md: "white" },
							boxShadow: { md: "0px 6px 30px rgba(0, 0, 0, 0.12)" },
						},
					}}
				>
					<Box sx={{ position: "relative", width: 24, height: 24 }}>
						<Fade in={open && !(lockDrawerOpen && !showLockButton)}>
							<Box sx={{ position: "absolute", left: 0 }}>
								<CertiblokIcon size={24} color="primary" name={"close"} />
							</Box>
						</Fade>
						<Fade in={!open && !(lockDrawerOpen && !showLockButton)}>
							<Box sx={{ position: "absolute", left: 0 }}>
								<CertiblokIcon size={24} color="primary" name={"menu-default"} iconCss={{ position: "absolute", left: 0 }} />
							</Box>
						</Fade>
						<Fade in={lockDrawerOpen && !showLockButton}>
							<Box sx={{ position: "absolute", left: 0 }}>
								<CertiblokIcon size={24} color="primary" name={"lock_outline_01"} iconCss={{ position: "absolute", left: 0 }} />
							</Box>
						</Fade>
					</Box>
				</IconButton>

				{isExactlyLg && (
					<LockDrawerButton showLockButton={showLockButton} handleShowLockButton={(value) => setShowLockButton(value)} />
				)}
				<div className="flex-1 min-w-0">
					{isMd ? (
						<GlobalAutocomplete />
					) : (
						<img src={logotype} alt="Certiblok logo" className="h-full max-h-9 object-contain max-w-full" />
					)}
				</div>

				<SelectedButton
					// selected={calendarOpen || location.pathname.includes("calendar")}
					// onClick={() => {
					// 	setCalendarOpen(true);
					// }}
					selected={location.pathname.includes("calendar")}
					href="calendar"
					icon={<CertiblokIcon size={24} color="primary" name={"calendar_outline"} />}
					selectedIcon={<CertiblokIcon size={24} color="primary" name={"calendar_bold"} />}
					size="medium"
					id="calendar-button"
				>
					{t("variousComponents.calendar")}
				</SelectedButton>
				<SelectedButton
					icon={<CertiblokIcon size={24} color="primary" name={"bell_outline"} />}
					selectedIcon={<CertiblokIcon size={24} color="primary" name={"bell_bold"} />}
					ref={notificationAnchorEl}
					selected={notificationOpen}
					onClick={() => {
						setNotificationOpen(!notificationOpen);
					}}
					count={notificationCount}
					id="notifications-button"
				>
					{isLg && t("variousComponents.notifications")}
				</SelectedButton>
				<SelectedButton
					LinkComponent={"a"}
					target="_blank"
					rel="noopener noreferrer"
					href="https://certiblok.com/faq/"
					selected={location.pathname.includes("/faq")}
					disabled={location.pathname.includes("/faq")}
					icon={<CertiblokIcon size={24} color="primary" name={"faq"} />}
					selectedIcon={<CertiblokIcon size={24} color="primary" name={"faq"} />}
					id="faq-button"
				/>
				{isMd && (!isCompanySelectorVisible || !isGuestInvitesEnabled) && (
					<SelectedButton
						selected={false}
						disabled={false}
						icon={<CertiblokIcon size={24} color="primary" name={"log_outline_02"} />}
						selectedIcon={<CertiblokIcon size={24} color="primary" name={"log_outline_02"} />}
						id="logout-button"
						onClick={() => {
							pushModal(LogoutModalWithNiceModal);
						}}
					/>
				)}
				{isCompanySelectorVisible && isGuestInvitesEnabled && (
					<SelectedButton
						ref={companySelectorAnchorEl}
						selected={companySelectorOpen}
						onClick={() => {
							setCompanySelectorOpen(!companySelectorOpen);
						}}
						icon={
							<div className="flex flex-row items-center gap-0 -mt-1">
								<div className="rounded-full w-6 h-6 md:w-8 md:h-8 overflow-hidden">
									<img
										src={backendMediaUrlParser(selectedCompany?.pictureUrl) ?? companyPlaceholder}
										alt="Company"
										className="w-full h-full bg-cover"
									/>
								</div>
								<CertiblokIcon size={20} color="primary" name={"▾-arrow-3"} />
							</div>
						}
						selectedIcon={
							<div className="flex flex-row items-center gap-0 -mt-1">
								<div className="rounded-full w-6 h-6 md:w-8 md:h-8 overflow-hidden">
									<img
										src={backendMediaUrlParser(selectedCompany?.pictureUrl) ?? companyPlaceholder}
										alt="Company"
										className="w-full h-full bg-cover"
									/>
								</div>
								<CertiblokIcon size={20} color="primary" name={"▾-arrow-1"} />
							</div>
						}
						id="company-switch-button"
						className={cn("items-center", companySelectorOpen && "[&_.MuiBox-root]:bg-white")}
					/>
				)}

				<NotificationPopper
					open={notificationOpen}
					setOpen={setNotificationOpen}
					anchorEl={notificationAnchorEl.current}
					readAllNotification={markAllNotificationAsRead}
					readOneNotification={markNotificationAsRead}
				/>

				<CompanySelector
					open={companySelectorOpen}
					setOpen={setCompanySelectorOpen}
					anchorEl={companySelectorAnchorEl.current}
				/>

				<Button href="/profile" className={clsx("group h-auto p-1", isInProfileSection && "bg-white")}>
					<div className="relative w-fit mr-2 mb-1 transition-transform">
						<div className="rounded-full w-10 h-10 md:w-12 md:h-12 overflow-hidden">
							<img
								src={backendMediaUrlParser(me?.profileUrl) ?? avatarPlaceholder}
								alt="Avatar"
								className="w-full h-full bg-cover"
							/>
						</div>
						<div
							className={cn(
								"w-5 h-5 md:w-6 md:h-6 rounded-full absolute bg-background bottom-0 right-0 translate-x-3 translate-y-2 border-4 border-solid border-background group-hover:border-[#D9ECE4] overflow-hidden transition-colors",
								isInProfileSection && "border-white group-hover:border-white"
							)}
						>
							<img
								src={backendMediaUrlParser(selectedCompany?.pictureUrl) ?? companyPlaceholder}
								alt="Company"
								className="w-full h-full bg-cover"
							/>
						</div>
					</div>
				</Button>
			</Toolbar>
		</StyledAppBar>
	);
};
export default AppBar;
